<template>
  <div>
    <form-item label="Startdatum" prop="startDate">
      <date-picker
        v-model="inputStartDate"
        name="startDate"
        type="date"
        placeholder="dd-mm-jjjj"
        style="width: 300px"
        format="dd-MM-yyyy"
        :start-date="new Date()"
      />
    </form-item>

    <form-item label="Sluitingsdatum:" prop="dates">
      <sb-info>
        <p>Na de sluitingsdatum is de organisatie niet meer actief</p>
      </sb-info>

      <div>
        <date-picker
          v-model="date"
          name="birth_date"
          type="date"
          placeholder="dd-mm-yyyy"
          style="width: 160px"
          format="dd-MM-yyyy"
          :start-date="new Date()"
        />
      </div>
    </form-item>

    <form-item label="Verlengbeleid" prop="renewalPolicy">
      <i-select
        v-model="data.renewalPolicy"
        style="max-width: 300px"
        placeholder="Kies een verlengbeleid"
      >
        <i-option value="NONE">Geen</i-option>
        <i-option value="MONTH">Maand</i-option>
        <i-option value="YEAR">Jaar</i-option>
      </i-select>
    </form-item>

    <!-- :disabled="
        !removeDateState &&
        !!(
          !date ||
          (startingDate && date.toString() === startingDate.toString())
        ) &&
        startDateNotChanged &&
        renewalPolicyNotChanged
      " -->
    <i-button type="primary" style="margin-left: 16px" @click="confirm">
      Aanpassen
    </i-button>
  </div>
</template>

<script>
import SetClosingDate from '@/graphql/mutations/SetClosingDate.gql';
import SbInfo from '@/components/SbInfo';
import filters from '@/lib/filters';
import UpdateOrganisation from '@/graphql/mutations/UpdateOrganisation.gql';
import GetOrganisationById from '@/graphql/queries/GetOrganisationById.gql';

export default {
  components: {
    SbInfo,
  },
  props: {
    organisationId: {
      type: String,
      default: null,
    },
    initialDate: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      startingDate: null,
      date: null,
      initialRenewalPolicy: null,
      data: {},
      inputStartDate: null,
    };
  },
  computed: {
    removeDateState() {
      return this.startingDate && !this.date;
    },
  },

  mounted() {
    // Old logic. I refuse to touch this.
    this.startingDate = this.initialDate ? new Date(this.initialDate) : null;
    this.date = this.startingDate;

    // New actual start date logic.
    this.initialData = this.data;

    if (this.data.startDate) {
      this.data = {
        ...this.data,
        startDate: new Date(this.data.startDate),
      };
    }
  },

  apollo: {
    schooldistrict: {
      fetchPolicy: 'no-cache',
      query: GetOrganisationById,
      variables() {
        return {
          id: this.organisationId || this.$user.context.resourceId,
        };
      },
      update(data) {
        this.data = data.getOrganisationById;
        this.inputStartDate = new Date(data.getOrganisationById.startDate);
      },
    },
  },

  methods: {
    confirm() {
      this.$Modal.confirm({
        title: 'Let op!',
        content: this.removeDateState
          ? 'Weet je zeker dat je de sluitingsdatum wilt verwijderen?'
          : 'Weet je zeker dat de gegevens wil aanpassen? Een school kan na deze datum niet meer inloggen.',
        closable: true,
        onOk: () => {
          setTimeout(async () => {
            await this.updateOrganisation();
            await this.setClosingDate();
          }, 300);
        },
      });
    },

    async updateOrganisation() {
      const { renewalPolicy } = this.data;

      const { errors } = await this.$apollo.mutate({
        mutation: UpdateOrganisation,
        variables: {
          id: this.data.id,
          data: {
            startDate: this.inputStartDate,
            renewalPolicy,
          },
        },
      });

      if (errors) {
        console.error(errors);
        throw new Error(errors.map((e) => e.message).join('\n'));
      }
    },

    async setClosingDate() {
      const { date, organisationId } = this;
      await this.$apollo.mutate({
        mutation: SetClosingDate,
        variables: {
          input: {
            organisationId,
            closingDate: this.removeDateState
              ? null
              : filters.reverseDate(date),
          },
        },
      });
      this.startingDate = this.date;
      this.$Message.success('De gegevens zijn succesvol gewijzigd');
      this.$emit('change');
    },
  },
};
</script>

<style></style>
