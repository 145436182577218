<template>
  <div>
    <sb-gql-table
      ref="table"
      :config="tableConfig"
      :query-options="queryOptions"
      :data-path="tableDataPath"
      @loading="loading = $event"
      @change="managers = $event"
      @row-action-edit="editManager"
      @row-action-edit-password="editPassword"
      @cell-click-username="viewManager"
    >
    </sb-gql-table>

    <div v-if="!managers || (!managers.length && loading)">
      Geen scholen gevonden
    </div>
  </div>
</template>

<script>
import Managers from '@/graphql/queries/Managers.gql';
import { nonReactiveMembersMixin } from '@/mixins/nonReactiveMembersMixin';
import { TableConfig } from './SbTable2/TableConfig';
import SbGqlTable from './SbTable2/SbGqlTable.vue';
import { StringFilter } from '@/lib/gql-filters';
import { educationTypeMixin } from '@/lib/education-type';

export default {
  components: {
    SbGqlTable,
  },

  mixins: [
    educationTypeMixin,
    nonReactiveMembersMixin((self) => {
      const tableConfig = new TableConfig({
        rowActions: [
          ['edit', 'Gegevens aanpassen'],
          ['edit-password', 'Wachtwoord veranderen'],
        ],
        columns: [
          {
            key: 'username',
            header: 'Gebruikersnaam',
            fixed: 'left',
            width: 250,
            action: true,
            sortable: true,
            filter: 'search',
            meta: { gqlFilter: StringFilter },
          },
          {
            key: 'fullName',
            header: 'Naam',
            sortable: true,
            filter: 'search',
            meta: { gqlFilter: StringFilter },
          },
          {
            key: 'lastlogin',
            header: 'Laatste inlogdatum',
            data: (row) =>
              row.lastLogin
                ? new Date(row.lastLogin).toLocaleDateString()
                : '-',
          },
        ],
      });

      return { tableConfig, tableDataPath: 'getOrganisationById.managers' };
    }),
  ],

  props: {
    organisationId: { type: String, default: undefined },
  },

  data() {
    return { managers: undefined, loading: false };
  },

  methods: {
    queryOptions({
      first,
      last,
      after,
      before,
      filter,
      filters,
      orderBy,
      pagination,
    }) {
      const query = Managers;
      const variables = {
        first,
        last,
        after,
        before,
        orderBy,
        id: this.organisationId,
        filter,
      };

      return { query, variables };
    },

    viewManager({ id }) {
      this.$router.push({
        name: 'ResourceManagementManagersManager',
        params: { managerId: id },
      });
    },

    editManager({ id }) {
      this.$router.push({
        name: 'ResourceManagementManagerEdit',
        params: { managerId: id },
      });
    },

    editPassword({ id }) {
      this.$router.push({
        name: 'ResourceManagementManagerEditPassword',
        params: { managerId: id },
      });
    },
  },
};
</script>

<style></style>
