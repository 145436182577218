<template>
  <form-item label="Aantal seats:" prop="seats">
    <input-number
      v-model.number="seats"
      type="number"
      name="seats"
      placeholder="Seats"
      style="width: 200px"
      :min="1"
      :step="1"
    />
    <i-button
      :disabled="seats === startingSeats"
      type="primary"
      style="margin-left: 16px"
      @click="confirmChangeSeats"
    >
      Aanpassen
    </i-button>
  </form-item>
</template>

<script>
import ChangeSeats from '@/graphql/mutations/ChangeSeats.gql';

export default {
  props: {
    organisationId: {
      type: String,
      default: null,
    },
    initialSeats: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      startingSeats: null,
      seats: null,
    };
  },

  mounted() {
    this.startingSeats = this.initialSeats;
    this.seats = this.startingSeats;
  },

  methods: {
    confirmChangeSeats() {
      this.$Modal.confirm({
        title: 'Let op!',
        content: 'Weet je zeker dat de seats wil aanpassen?',
        closable: true,
        onOk: () => {
          setTimeout(() => {
            this.changeSeats();
          }, 300);
        },
      });
    },

    async changeSeats() {
      const { seats, organisationId } = this;
      if (!seats || seats < 1 || seats % 1 != 0) {
        return this.$Modal.warning({
          title: 'Foutieve invoer',
          content: 'Het aantal seats moet minstens één zijn.',
        });
      }

      const { data } = await this.$apollo.mutate({
        mutation: ChangeSeats,
        variables: {
          input: {
            organisationId,
            seats,
          },
        },
      });
      this.startingSeats = this.seats;
      this.$Message.success('De seats zijn succesvol gewijzigd');
      console.log('update seats', data);
    },
  },
};
</script>

<style></style>
