<template>
  <div>
    <sb-loading v-if="$store.state.loading" />

    <sb-page-header>
      <template v-slot:left>
        <h1>
          {{ organisationLabel }}
          {{ editMode ? 'aanpassen' : 'toevoegen' }}
        </h1>
      </template>
    </sb-page-header>
    <!-- <SbCallOut
      v-if="$user.context.isAdmin && !editMode"
      style="max-width: 575px;"
    >
      <template v-slot:left>
        ℹ️
      </template>
      <template v-slot:right>
        <b>Let op</b>
        <p>
          Als de school die je wilt toevoegen onder een bestaand schooldisctrict valt, voeg de school dan toe via het schooldistrict.
        </p>
      </template>
    </SbCallOut> -->
    <i-form
      id="school-add-edit"
      ref="organisation"
      :model="organisation"
      :rules="validateFormAddOrganisation"
      :label-width="160"
    >
      <!-- <template
        v-if="editMode"
      >
        <SbDivider
          title="Instellingen"
        />

        <FormItem
          label="Verwerkings- overeenkomst getekend"
          prop="receivedSignedDataProcessingAgreement"
        >
          <ISwitch
            v-model="organisation.receivedSignedDataProcessingAgreement"
          >
            <span slot="open">Ja</span>
            <span slot="close">Nee</span>
          </ISwitch>
        </FormItem>
      </template> -->

      <sb-divider title="Algemeen" />

      <sb-spacer height="20" />

      <form-item label="Naam" prop="name">
        <i-input
          v-model="organisation.name"
          name="companyName"
          placeholder="Naam"
        />
      </form-item>

      <form-item
        v-if="organisationType === 'school'"
        label="Onderwijstype"
        prop="educationType"
      >
        <i-select
          v-model="organisation.educationType"
          style="max-width: 300px"
          placeholder="Kies het onderwijstype"
        >
          <i-option v-for="item in EducationType" :key="item" :value="item">
            {{ getEducationTypeLabel(item) }}
          </i-option>
        </i-select>
      </form-item>

      <!-- <FormItem label="BRIN nummer" prop="brin">
        <Input v-model="organisation.brinNumber" placeholder="BRIN nummer" />
      </FormItem>-->

      <form-item label="KVK-nummer:" prop="chamberOfCommerceNumber">
        <i-input
          v-model="organisation.chamberOfCommerceNumber"
          name="chamberOfCommerceNumber"
          placeholder="KVK-nummer"
        />
      </form-item>

      <template v-if="organisationType === 'schoolDistrict'">
        <form-item label="Schooldistrict beheert alle seats">
          <sb-info :inline="true">
            <p>
              Kies of het schooldistrict de seats beheert. De onderliggende
              scholen maken dan gebruik van de seats van het schooldistricts.
              Als het schooldistrict de seats niet beheert, dan maakt de school
              gebruik van eigen seats.
            </p>
          </sb-info>
          <i-switch
            v-model="organisation.invoiceSelf"
            style="margin-left: -30px"
          >
            <span slot="open">Ja</span>
            <span slot="close">Nee</span>
          </i-switch>
        </form-item>
      </template>

      <form-item
        v-if="
          (organisationType === 'school' ||
            (organisationType === 'schoolDistrict' &&
              organisation.invoiceSelf)) &&
          !editMode
        "
        label="Aantal seats:"
        prop="seats"
      >
        <input-number
          v-model.number="organisation.seats"
          type="number"
          name="seats"
          prop="seats"
          placeholder="0"
          :step="1"
          :min="0"
        />
      </form-item>

      <form-item
        v-if="
          organisationType === 'school' ||
          organisationType === 'schoolDistrict' ||
          0 == 0
        "
        label="BRIN"
        prop="brin"
      >
        <i-input
          v-model="organisation.brin"
          name="brin"
          placeholder="BRIN-nummer"
        />
      </form-item>
      <!-- <FormItem
        v-if="brinCandidate"
        label="BRIN-nummer:"
        prop="brinNumber"
      >
        <Input
          v-model="organisation.brinNumber"
          name="brinNumber"
          placeholder="BRIN-nummer"
        />
      </FormItem> -->
      <sb-spacer height="30" />

      <sb-divider title="Contactpersoon" />

      <sb-spacer height="20" />

      <form-item label="Naam" prop="contactPerson">
        <i-input
          v-model="organisation.contact.name"
          name="contact"
          placeholder="Contactpersoon"
        />
      </form-item>

      <form-item label="E-mailadres" prop="contact.email">
        <i-input
          v-model="organisation.contact.email"
          name="contact.email"
          placeholder="E-mailadres"
        />
      </form-item>
      <form-item label="Telefoonnummer" prop="contact.phone">
        <i-input
          v-model="organisation.contact.phone"
          name="contact.phone"
          placeholder="Telefoonnummer"
        />
      </form-item>

      <section v-if="!editMode">
        <sb-spacer height="30" />
        <sb-divider title="Beheerder" />
        <form-item label="Voornaam:" prop="manager.firstName">
          <i-input
            v-model="organisation.manager.firstName"
            name="firstName"
            placeholder="Voornaam"
          />
        </form-item>
        <form-item label="Tussenvoegsel:" prop="manager.prefix">
          <i-input
            v-model="organisation.manager.prefix"
            name="prefix"
            placeholder="Tussenvoegsel"
          />
        </form-item>
        <form-item label="Achternaam:" prop="manager.lastName">
          <i-input
            v-model="organisation.manager.lastName"
            name="lastName"
            placeholder="Achternaam"
          />
        </form-item>
        <form-item label="E-mailadres:" prop="manager.email">
          <sb-info>
            <p>De gebruiker zal een mail krijgen om de account te activeren.</p>
          </sb-info>
          <i-input
            v-model="organisation.manager.email"
            name="email"
            placeholder="E-mailadres"
          />
        </form-item>
        <form-item label="Gebruikersnaam:" prop="manager.username">
          <sb-info>
            <p>
              Als geen gebruikersnaam wordt ingevoerd, dan wordt er automatisch
              een gegenereeerd.
            </p>
          </sb-info>
          <i-input
            v-model="organisation.manager.username"
            name="username"
            placeholder="Gebruikersnaam"
          />
        </form-item>
        <form-item label="Wachtwoord:" prop="manager.password">
          <sb-info>
            <p>
              Als geen wachtwoord wordt ingevoerd, dan wordt er automatisch een
              gegenereeerd.
            </p>
          </sb-info>
          <i-input
            v-model="organisation.manager.password"
            type="password"
            name="password"
            placeholder="Wachtwoord"
          />
        </form-item>
      </section>

      <sb-spacer height="30" />

      <sb-divider title="Bezoekadres" />

      <form-item label="Land" prop="address.country">
        <i-select
          v-model="organisation.address.country"
          style="max-width: 300px"
          name="country"
          placeholder="Kies een land"
        >
          <i-option
            v-for="item in countries"
            :key="item.code"
            :value="item.code"
          >
            {{ item.label }}
          </i-option>
        </i-select>
      </form-item>

      <form-item label="Straatnaam" prop="address.street">
        <i-input
          v-model="organisation.address.street"
          name="address.street"
          placeholder="Straatnaam"
        />
      </form-item>
      <form-item label="Huisnummer" prop="address.streetNumber">
        <i-input
          v-model="organisation.address.streetNumber"
          name="address.streetNumber"
          placeholder
          style="max-width: 100px"
        />
      </form-item>
      <form-item label="Postcode" prop="address.postalCode">
        <i-input
          v-model="organisation.address.postalCode"
          name="addresszipCode"
          :placeholder="postalCodePlaceholder"
          style="max-width: 100px"
        />
      </form-item>
      <form-item label="Plaats" prop="address.city">
        <i-input
          v-model="organisation.address.city"
          name="city"
          placeholder="Plaats"
        />
      </form-item>
    </i-form>

    <sb-spacer height="400" />

    <sb-bottom-fixed-buttons>
      <template #left>
        <i-button
          style="margin-left: 8px"
          size="large"
          @click="$router.navigateBack"
        >
          Annuleren
        </i-button>
      </template>
      <template #right>
        <i-button type="primary" size="large" @click="handleSubmit('school')">
          {{ editMode ? 'Aanpassen' : 'Toevoegen' }}
        </i-button>
      </template>
    </sb-bottom-fixed-buttons>
  </div>
</template>

<script>
import * as _ from 'lodash';

import {
  validatePostalCode,
  validateChamberOfCommerceNumber,
} from '@/lib/validators';
import { findAndReplaceObject, deepOmit } from '@/lib/util-helper';
import filters from '@/lib/filters';
import SbLoading from '@/components/SbLoading';
import SbBottomFixedButtons from '@/components/SbBottomFixedButtons';
import SbDivider from '@/components/SbDivider';
import SbPageHeader from '@/components/SbPageHeader';
import SbInfo from '@/components/SbInfo';
import Countries from '@/graphql/queries/Countries.gql';
import EducationTypes from '@/graphql/queries/EducationTypes.gql';
import RegisterOrganisation from '@/graphql/mutations/RegisterOrganisation.gql';
import UpdateOrganisation from '@/graphql/mutations/UpdateOrganisation.gql';
import { changeLoggerMixin } from '@/mixins/changeLogger';
import { educationTypeMixin } from '@/lib/education-type';

export default {
  components: {
    SbBottomFixedButtons,
    SbDivider,
    SbPageHeader,
    SbLoading,
    SbInfo,
  },
  mixins: [changeLoggerMixin(['organisation']), educationTypeMixin],
  props: {
    data: {
      type: Object,
      default: null,
    },
    resourceId: {
      type: String,
      default: null,
    },
    resourceType: {
      type: String,
      default: null,
    },
    organisationType: {
      type: String,
      required: true,
    },
    manageMethod: {
      type: String,
      required: true,
    },
  },

  data() {
    const validateVat = (rule, value, callback) => {
      if (!validateChamberOfCommerceNumber(value))
        return callback('Voer een correct KVK-nummer in');
      return true;
    };

    const validateUsername = (rule, value, callback) => {
      if (value !== null && value !== value.toLowerCase()) {
        callback(new Error('De gebruikersnaam moet in kleine letters zijn.'));
      } else {
        callback();
      }
    };

    return {
      countryCodes: {
        NL: 'Nederland',
        BE: 'België',
      },
      datePickerStartDateOptions: {
        disabledDate(date) {
          return date && date.valueOf() < Date.now() - 86400000;
        },
      },
      coupon: {
        valid: null,
        slug: null,
        discount: null,
      },
      registerWithPassword: false,
      username: null,
      showResult: false,
      organisation: {
        invoiceSelf: false,
        cardSuggestionsEnabled: false,
        educationType: null,
        organisationType: null,
        name: null,
        closingDate: null,
        renewalPolicy: null,
        brin: undefined,
        seats: null,
        contact: {
          name: null,
          email: null,
          phone: null,
        },
        chamberOfCommerceNumber: null,
        // acceptsDataProcessingAgreement: true,
        // acceptsTermsAndConditions: true,
        // receivedSignedDataProcessingAgreement: false,
        address: {
          street: null,
          streetNumber: null,
          postalCode: null,
          city: null,
          country: 'NL',
        },
        manager: {
          firstName: null,
          prefix: null,
          lastName: null,
          username: null,
          password: null,
          email: null,
          secondaryEmails: [],
        },
      },

      validateFormAddOrganisation: {
        seats: [
          {
            required: false,
            validator: (rule, value, callback) => {
              if (value && value < 1) {
                return callback('Het aantal licenties moet minstens 1 zijn.');
              }
              return true;
            },
          },
        ],
        chamberOfCommerceNumber: [
          {
            required: false,
            validator: validateVat,
            trigger: 'blur',
          },
        ],
        name: [
          {
            required: true,
            message: 'Voer een naam van de school in',
            trigger: 'blur',
          },
        ],
        'contact.phone': [
          {
            required: false,
            message: 'Voer een telefoonnummer van de school in',
            trigger: 'blur',
          },
          {
            validator: this.validatePhoneNumber.bind(this),
            trigger: 'blur',
          },
        ],
        'contact.email': [
          {
            required: true,
            message: 'Voer een email van de school in',
            trigger: 'blur',
          },
          {
            type: 'email',
            message: 'Incorrecte e-mail',
            trigger: 'blur',
          },
        ],
        'manager.firstName': [
          {
            required: true,
            message: 'Voer een voornaam in',
            trigger: 'blur',
          },
        ],
        'manager.lastName': [
          {
            required: true,
            message: 'Voer een achternaam in',
            trigger: 'blur',
          },
        ],
        'manager.email': [
          {
            required: true,
            message: 'Voer een e-mailadres in',
            trigger: 'blur',
          },
          {
            type: 'email',
            message: 'Incorrecte e-mail',
            trigger: 'blur',
          },
        ],
        'manager.username': [
          {
            required: false,
            trigger: 'blur',
            validator: validateUsername,
          },
        ],
        educationType: [
          {
            required: true,
            message: 'Selecteer een onderwijstype',
            trigger: 'change',
          },
        ],
        'address.country': [
          {
            required: true,
            message: 'Selecteer een land',
            trigger: 'change',
          },
        ],
        'address.street': [
          {
            required: true,
            message: 'Voer een straatnaam in',
            trigger: 'blur',
          },
        ],
        'address.streetNumber': [
          {
            required: true,
            message: 'Voer een huisnummer in',
            trigger: 'blur',
          },
          // {
          //   validator: this.validatePhoneNumber.bind(this),
          //   trigger: 'blur'
          // }
        ],
        'address.postalCode': [
          {
            required: true,
            message: 'Voer een postcode in',
            trigger: 'blur',
          },
          {
            validator: this.validatePostalCode.bind(this),
            trigger: 'blur',
          },
        ],
        'address.city': [
          {
            required: true,
            message: 'Voer een plaats in',
            trigger: 'blur',
          },
        ],
      },
    };
  },

  computed: {
    organisationLabel() {
      return {
        school: 'School',
        schoolDistrict: 'Schooldistrict',
      }[this.organisationType];
    },

    editMode() {
      return this.manageMethod === 'edit';
    },

    postalCodePlaceholder() {
      switch (this.organisation.address.country) {
        case 'NL':
          return '000AA';
        case 'BE':
          return '0000';
        default:
          return 'Postcode';
      }
    },
  },

  watch: {
    organisation: {
      handler(value) {
        if (this.organisationType !== 'schoolDistrict') return;
        this.organisation.seats = value.invoiceSelf ? value.seats || 1 : null;
      },
      deep: true,
    },

    'organisation.closingDate': {
      handler(newValue) {
        if (!!newValue && this.organisation.renewalPolicy !== 'NONE') {
          this.$Message.warning(
            'Als er een definitieve sluitingsdatum is, dan kan er geen verlengbeleid worden ingesteld.',
          );
          this.organisation.renewalPolicy = 'NONE';
        }
      },
    },

    'organisation.renewalPolicy': {
      handler(newValue) {
        if (newValue !== 'NONE' && !!this.organisation.closingDate) {
          this.$Message.warning(
            'Als er een verlengbeleid is, dan kan er geen sluitingsdatum worden ingesteld.',
          );
          this.organisation.closingDate = '';
        }
      },
      deep: true,
    },
  },

  apollo: {
    educationTypes: {
      query: EducationTypes,
    },
    countries: {
      query: Countries,
    },
  },
  mounted() {
    if (this.editMode) {
      // use this.school as blueprint to filter out redundant values passed by this.data
      this.organisation = _.assign(
        this.organisation,
        _.pick(this.data, _.keys(this.organisation)),
      );

      if (!!this.organisation.startDate) {
        this.organisation.startDate = new Date(this.organisation.startDate);
      }

      if (!!this.organisation.closingDate) {
        this.organisation.closingDate = new Date(this.organisation.closingDate);
      }
    }
  },
  methods: {
    getDisabledClosingDate(current) {
      const today = new Date();
      today.setDate(today.getDate() + 1);
      if (!current) return true;
      return new Date(today.toDateString()) > new Date(current.toDateString());
    },

    navigateBack() {
      if (window.history?.length > 2) {
        this.$router.go(-1);
      } else {
        this.$router.push('/');
      }
    },

    async addOrganisation() {
      const parsedData = JSON.parse(JSON.stringify(this.organisation));
      const {
        invoiceSelf,
        educationType,
        seats,
        ...data
      } = findAndReplaceObject(parsedData, '', null);
      let mutationData;

      if (data.closingDate) {
        data.closingDate = filters.reverseDate(data.closingDate);
      }

      // set organisation type
      data.organisationType = {
        school: 'SCHOOL',
        schoolDistrict: 'SCHOOL_DISTRICT',
      }[this.organisationType];

      console.log('data', data);

      if (data.organisationType === 'SCHOOL_DISTRICT') {
        if (!invoiceSelf) {
          mutationData = {
            invoiceSelf,
            ...data,
          };
        } else {
          mutationData = {
            seats,
            invoiceSelf,
            ...data,
          };
        }
      }

      if (data.organisationType === 'SCHOOL') {
        mutationData = {
          seats,
          educationType,
          ...data,
        };
      }

      const { errors } = await this.$apollo.mutate({
        mutation: RegisterOrganisation,
        variables: {
          input: mutationData,
        },
      });

      if (errors) {
        console.error(errors);
        throw new Error(errors.map((e) => e.message).join('\n'));
      }
    },

    async updateOrganisation() {
      const parsedData = JSON.parse(JSON.stringify(this.organisation));
      const {
        organisationType,
        seats,
        manager,
        startDate,
        closingDate,
        renewalPolicy,
        ...data
      } = deepOmit(parsedData, ['id', '__typename']);

      if (organisationType === 'SCHOOL') {
        data.invoiceSelf = null;
      }

      const { errors } = await this.$apollo.mutate({
        mutation: UpdateOrganisation,
        variables: {
          id: this.data.id,
          data,
        },
      });

      if (errors) {
        console.error(errors);
        throw new Error(errors.map((e) => e.message).join('\n'));
      }
    },

    async handleSubmit() {
      const valid = await this.$refs.organisation.validate();

      if (!valid) {
        return void this.$Message.error(
          'Er zijn een aantal fouten, controleer je invoer.',
        );
      }

      try {
        this.$store.state.loading = true;

        await (this.editMode
          ? this.updateOrganisation()
          : this.addOrganisation());

        this.$refs.organisation.resetFields();

        this.$Modal.success({
          title: 'Succes',
          content: this.editMode
            ? `${this.organisationLabel} is succesvol aangepast`
            : `${this.organisationLabel} is succesvol aangemaakt.`,
          onOk: () => {
            setTimeout(() => {
              // JP: sorry ugly hack to fix address and contact caching issues with Apollo
              location.reload();
            }, 500);
            this.$router.navigateBack();
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    validatePostalCode(rule, value, callback) {
      try {
        validatePostalCode(value, this.organisation.address.country);
        return callback();
      } catch (error) {
        return callback(error);
      }
    },
    validatePhoneNumber(rule, value, callback) {
      try {
        return callback();
      } catch (error) {
        return callback(error);
      }
    },
  },
};
</script>

<style lang="scss"></style>
