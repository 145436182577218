<template>
  <div v-if="active !== null">
    <i-button
      v-if="active"
      type="error"
      @click="confirmSwitchActivation(false)"
    >
      {{ label }} Deactiveren
    </i-button>
    <i-button
      v-if="!active"
      type="primary"
      @click="confirmSwitchActivation(true)"
    >
      {{ label }} Activeren
    </i-button>
  </div>
</template>

<script>
import ActivateOrganisations from '@/graphql/mutations/ActivateOrganisations.gql';
import DeactivateOrganisations from '@/graphql/mutations/DeactivateOrganisations.gql';

export default {
  props: {
    active: {
      type: Boolean,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
  },
  methods: {
    confirmSwitchActivation(active) {
      this.$Modal.confirm({
        title: 'Weet je het zeker?',
        content: active
          ? `Weet je zeker dat je deze ${this.label} wil activeren?`
          : `Weet je zeker dat je deze ${this.label} wil deactiveren? Geen van de gebruikers van de ${this.label} kunnen dan meer inloggen.`,
        onOk: async () => {
          const { data } = await this.$apollo.mutate({
            mutation: active ? ActivateOrganisations : DeactivateOrganisations,
            variables: {
              input: [this.id],
            },
          });
          if (
            data.deactivateOrganisations === true ||
            data.activateOrganisations === true
          ) {
            this.$emit('change', active ? 'activated' : 'deactivated');
            setTimeout(() => {
              this.$Message.info({
                content: `${this.label} is succesvol ${
                  active ? 'geactiveerd' : 'gedeactiveerd'
                }`,
              });
            }, 100);
          }
          console.log('activeer', this.label, data);
        },
      });
    },
  },
};
</script>

<style></style>
