import { isPostalCode } from 'validator';

export function validatePostalCode(value, country) {
  if (value) {
    switch (country) {
      case 'NL':
      case 'BE':
        if (!isPostalCode(value, country)) {
          throw new Error('Voer een juiste postcode in');
        }
        break;
      default:
        break;
    }
  }
}

export function validateChamberOfCommerceNumber(/* value */) {
  // TODO: KVK API check

  // const vatCheckRegEx = /((?<NL>(NL)[0-9]{9}B[0-9]{2})|(?<BE>(BE)0[0-9]{9}))/;
  // const normalizeVat = vat => vat.toUpperCase().replace(/[^A-Z0-9]/g, '');
  // const checkVat = vat => normalizeVat(vat).match(vatCheckRegEx);

  // return !!checkVat(value);
  return true;
}
